.arx-reset * {
  box-sizing: border-box;
  background: 0 0;
  border: 0;
  vertical-align: baseline;
}

.arx-reset b:empty,
.arx-reset em:empty,
.arx-reset i:empty,
.arx-reset ins:empty,
.arx-reset span:empty,
.arx-reset strong:empty,
.arx-reset sub:empty,
.arx-reset sup:empty,
.arx-reset u:empty {
  display: inline-block;
  min-width: 1px;
  min-height: 1em;
}

.arx-reset table {
  empty-cells: show;
}

.arx-reset embed,
.arx-reset img,
.arx-reset object {
  max-width: 100%;
  height: auto;
}

.arx-reset figure {
  display: block;
  max-width: 100%;
  margin: 0;
}

.arx-editor figure img {
  display: block;
}

.arx-content code {
  background-color: #f0f0f0;
  color: rgba(34, 34, 34, 0.85);
}

.arx-content figcaption {
  color: rgba(34, 34, 34, 0.75);
}

.arx-content kbd {
  color: #222;
}

.arx-content pre,
.arx-content pre code {
  background-color: #fff;
  border: 1px solid rgba(17, 17, 17, 0.07);
  color: rgba(17, 17, 17, 0.8);
}

.arx-content mark {
  background: #ff0;
}

.arx-content blockquote p {
  color: rgba(17, 17, 17, 0.8);
}

.arx-content blockquote cite {
  color: rgba(17, 17, 17, 0.5);
}

.arx-content ol,
.arx-content ul {
  padding-left: 20px;
}

.arx-content ol ol,
.arx-content ol ul,
.arx-content ul ol,
.arx-content ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

.arx-content blockquote {
  font-style: normal;
  padding: 1.5em 2em;
  background-color: #f8f8f8;
}

.arx-content blockquote cite {
  font-style: inherit;
}

.arx-content pre,
.arx-content pre code {
  display: block;
}

.arx-content pre {
  border-radius: 3px;
  max-width: 100%;
  padding: 1em 1.25em;
  overflow: auto;
  white-space: pre;
}

.arx-content sub,
.arx-content sup {
  margin-left: 2px;
}

.arx-content sub {
  vertical-align: sub;
}

.arx-content sup {
  vertical-align: super;
}

.arx-content code,
.arx-content kbd,
.arx-content samp,
.arx-content var {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
}

.arx-content code {
  padding: 0.2em 0.4em;
}

.arx-content pre code {
  display: block;
  border: none;
}

.arx-content kbd {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0.2em 0.4em;
}

.arx-content table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.arx-content table,
.arx-content td,
.arx-content th {
  border: 1px solid #eee;
}

.arx-content caption {
  caption-side: top;
  margin-top: 0;
  text-align: left;
}

.arx-content td,
.arx-content th {
  padding: 0.7em;
  text-align: left;
}

.arx-content hr {
  position: relative;
  height: 12px;
  line-height: 0;
  background: 0 0;
  border: none;
}

.arx-content hr:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 100%;
  margin-top: -6px;
  margin-left: -50%;
  border-top: 1px solid #ddd;
}

.arx-content .align-left {
  text-align: left;
}

.arx-content .align-center {
  text-align: center;
}

.arx-content .align-center img {
  margin-left: auto;
  margin-right: auto;
}

.arx-content .align-center figcaption {
  text-align: center;
}

.arx-content .align-right {
  text-align: right;
}

.arx-content .align-right img {
  margin-left: auto;
}

.arx-content .align-right figcaption {
  text-align: right;
}

.arx-content .align-justify {
  text-align: justify;
}

.arx-content .valign-top {
  align-items: start;
}

.arx-content .valign-middle {
  align-items: center;
}

.arx-content .valign-bottom {
  align-items: end;
}

.arx-content .outset-left {
  width: calc(100% + 26px);
  max-width: calc(100% + 26px);
  transform: translateX(-26px);
  position: relative;
}

.arx-content .outset-right {
  width: calc(100% + 26px);
  max-width: calc(100% + 26px);
  transform: translateX(26px);
  position: relative;
  left: -26px;
}

.arx-content .outset-both {
  width: calc(100% + 52px);
  max-width: calc(100% + 52px);
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}

.arx-content .embed-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.arx-content .embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.arx-content .grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 12px;
}

.arx-content .column {
  margin: 0;
}

.arx-content .column-1 {
  grid-column: span 1;
}

.arx-content .column-2 {
  grid-column: span 2;
}

.arx-content .column-3 {
  grid-column: span 3;
}

.arx-content .column-4 {
  grid-column: span 4;
}

.arx-content .column-5 {
  grid-column: span 5;
}

.arx-content .column-6 {
  grid-column: span 6;
}

.arx-content .column-7 {
  grid-column: span 7;
}

.arx-content .column-8 {
  grid-column: span 8;
}

.arx-content .column-9 {
  grid-column: span 9;
}

.arx-content .column-10 {
  grid-column: span 10;
}

.arx-content .column-11 {
  grid-column: span 11;
}

.arx-content .column-12 {
  grid-column: span 12;
}

@media only screen and (max-width: 767px) {
  .arx-content .grid {
    display: block;
  }
}
